import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.fonts.roboto};
    
  }
  .site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  main {
    flex: 1;
  }
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  h1, h2, h3, h4, h5, p, ul, li {
    padding: 0;
    margin: 0;
  }
  h1 {
    font-size: 26px;
    font-weight: 900;
    font-family: ${({ theme }) => theme.fonts.bodoniModa};

    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.bodoniModa};

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }
  p {
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-weight: 300;
        
    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }
  a {
    text-decoration: none;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.sapphireBlue};
  }
`

export default GlobalStyle;
