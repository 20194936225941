import React from 'react'
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './src/theme/globalStyles'
import { theme } from './src/theme/theme'

export const wrapPageElement = ({ element }) => (
  <ThemeProvider theme={ theme }>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)