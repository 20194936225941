export const theme = {
  colors: {
    sapphireBlue: "#06376f",
    white: "#ffffff",
    inkyBlack: "#16151e",
    lightGrey: "#6d6e6f"
  },
  fonts: {
    bodoniModa: "Bodoni Moda",
    roboto: "Roboto"
  },
  fontSizes: {
    xsmall: "14px",
    small: "16px",
    medium: "18px",
    large: "20px",
    xlarge: "22px",
  }
};
